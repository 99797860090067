import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import LinkCards from '../components/LinkCards'
import Layout from '../components/Layout'
import SVGBorderButton from '../components/SVG/SVGBorderButton'
import Breadcrumb from '../components/Breadcrumb'
import Banner from '../components/Banner'
import Container from '../components/Container'

export const InPracticePage1Template = ({
  bannerimage,
  title,
  theme,
  linkcards,
  sectionwithlink,
}) => (
  <>
    <Banner bannerImage={bannerimage} title={title} theme={theme} />
    <Breadcrumb to="in-practice" label="In Practice" />
    <Container className="py-16 sm:py-20">
      <LinkCards linkcards={linkcards} />
    </Container>
    <Container className="py-16 sm:py-20" outerClassName="bg-teal text-center">
      <h1 className="leading-tight text-3xl font-light text-white font-header uppercase">
        {sectionwithlink.title}
      </h1>
      <p className="font-body text-white pb-8 m-auto">{sectionwithlink.body}</p>
      <SVGBorderButton
        label={sectionwithlink.linklabel}
        to={sectionwithlink.link}
        bgColor="white"
        bgColorHover="#189CD8"
        borderColor="white"
        borderColorHover="white"
        textColor="#189CD8"
        textColorHover="white"
      />
    </Container>
  </>
)

InPracticePage1Template.propTypes = {
  bannerimage: PropTypes.object,
  title: PropTypes.string,
  theme: PropTypes.string,
  linkcards: PropTypes.array,
  sectionwithlink: PropTypes.object,
}

const InPracticePage1 = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout
      ogtitle={frontmatter.ogtitle}
      ogdescription={frontmatter.ogdescription}
      ogimage={frontmatter.ogimage}
    >
      <InPracticePage1Template
        bannerimage={frontmatter.bannerimage}
        title={frontmatter.title}
        theme={frontmatter.theme}
        linkcards={frontmatter.linkcards}
        sectionwithlink={frontmatter.sectionwithlink}
      />
    </Layout>
  )
}

InPracticePage1.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default InPracticePage1

export const pageQuery = graphql`
  query InPracticePage1Template($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        opengraph {
          ogtitle
          ogdescription
          ogimage {
            childImageSharp {
              fluid(maxWidth: 300, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        title
        theme
        bannerimage {
          alt
          desktopimage {
            childImageSharp {
              fluid(maxWidth: 1600, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          mobileimage {
            childImageSharp {
              fluid(maxWidth: 833, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        linkcards {
          title
          body
          link
          linklabel
          cardimage {
            image {
              childImageSharp {
                fluid(maxWidth: 300, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
          body
        }
        sectionwithlink {
          title
          body
          link
          linklabel
        }
      }
    }
  }
`
