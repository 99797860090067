import React from 'react'
import PropTypes from 'prop-types'
import { v4 } from 'uuid'
import SVGBorderButton from './SVG/SVGBorderButton'
import Image from './Image'

const CardPattern = require('../../static/img/Patterns/cardpattern.png')

const LinkCards = ({ linkcards }) => (
  <ul className="flex flex-wrap justify-center lg:mx-32 xl:mx-20">
    {linkcards.map(card => (
      <li
        className="xl:p-3 pb-8 w-full xl:w-1/3 text-center flex flex-col items-center"
        key={v4()}
      >
        <div className="w-card md:w-full xl:w-card overflow-hidden flex flex-col md:flex-row xl:flex-col justify-center">
          <div
            className="w-48 flex flex-col self-center justify-center"
            style={{ minHeight: `192px` }}
          >
            <div className="content-center items-center">
              <Image imageInfo={card.cardimage} />
            </div>
          </div>
          <div className="px-6 py-4">
            <h2 className="uppercase text-blue font-header text-2xl mb-2">
              {card.title}
            </h2>
            <div className="flex m-auto w-64 pb-6">
              <p className="font-body text-black-darker">{card.body}</p>
            </div>
            <SVGBorderButton
              label={card.linklabel}
              to={card.link}
              bgColor="#189CD8"
              bgColorHover="#9AC5E9"
              borderColor="#189CD8"
              borderColorHover="#189CD8"
              textColor="white"
              textColorHover="#243D91"
            />
          </div>
        </div>
      </li>
    ))}
  </ul>
)

LinkCards.propTypes = {
  linkcards: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      body: PropTypes.string,
      linklabel: PropTypes.string,
      link: PropTypes.link,
      cardimage: PropTypes.object,
    })
  ),
}

export default LinkCards
